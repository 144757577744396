import { createBrowserRouter } from "react-router-dom";
import Home from './pages/Home'

const router=createBrowserRouter([
    {
        path:'/',
        element:<Home/>
    },
    {
        path:'/profile',
        element:<>this is profile</>
    }

])

export default router