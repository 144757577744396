import React, { Fragment, useRef, useState } from "react";
import styles from "./FeatureServices.module.scss";
import settingsGIF from "../../assets/gifs/settings.gif";
import SingleCard from "./SingleCard";
import { ServiceData } from "../../utils/servicesCards";

const Index = () => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
  };

  return (
    <Fragment>
      <div className={styles.info}>
        <div className={styles.text}>
          <img
            src={settingsGIF}
            className={styles.settingIcon}
            alt="Settings Icon"
          />
          <h1 className={styles.feature}>Featured </h1>
          <h1 className={styles.service}>Services </h1>
        </div>
        <div className={styles.para}>
          <p className={styles.title}>An overview of what we do</p>
          <p className={styles.content}>
            At Cloudncloudtech.com, we offer a range of comprehensive services
            to cater all your web development needs. Here are some of our
            featured services:
          </p>
        </div>
      </div>

      <div className={styles.cardsDiv}>
        <div
          onClick={() => slide(-2000)}
          className={`${styles.card} ${styles.arrowDiv}`}
        >
          <div className={`${styles.leftArrow} ${styles.arrowIcon}`}></div>
        </div>
        <div className={styles.scrollCards} ref={scrl}>
          {/* {ServiceData.map((elem) => {
            return (
              <div key={elem.id} className={styles.card}>
                <SingleCard {...elem} />
              </div>
            );
          })} */}

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv1}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>1</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Website Development</p>
                  <p className={styles.about}>
                    we understand the importance of a well-crafted online
                    presence. Whether you're a small business looking to
                    establish your brand or a large enterprise seeking to
                    enhance your digital footprint, we have the expertise to
                    deliver exceptional web development solutions tailored to
                    your unique needs.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv2}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>2</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Mobile Apps</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv3}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>3</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Digital Marketing</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv4}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>4</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Content Writing</p>
                  <p className={styles.about}>
                    we understand the importance of a well-crafted online
                    presence. Whether you're a small business looking to
                    establish your brand or a large enterprise seeking to
                    enhance your digital footprint, we have the expertise to
                    deliver exceptional web development solutions tailored to
                    your unique needs.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv5}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>5</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Reputation Management</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv6}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>6</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Email Marketing</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => slide(+2000)}
          className={`${styles.card} ${styles.arrowDiv}`}
        >
          <div className={`${styles.rightArrow} ${styles.arrowIcon}`}></div>
        </div>
      </div>

      <div className={styles.mobilecardsDiv}>
        <div className={styles.scrollCards} ref={scrl}>
          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv1}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>1</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Website Development</p>
                  <p className={styles.about}>
                    we understand the importance of a well-crafted online
                    presence. Whether you're a small business looking to
                    establish your brand or a large enterprise seeking to
                    enhance your digital footprint, we have the expertise to
                    deliver exceptional web development solutions tailored to
                    your unique needs.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv2}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>2</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Mobile Apps</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv3}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>3</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Digital Marketing</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv4}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>4</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Content Writing</p>
                  <p className={styles.about}>
                    we understand the importance of a well-crafted online
                    presence. Whether you're a small business looking to
                    establish your brand or a large enterprise seeking to
                    enhance your digital footprint, we have the expertise to
                    deliver exceptional web development solutions tailored to
                    your unique needs.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv5}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>5</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Reputation Management</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.singleCard}>
              <div className={styles.logo}>
                <div className={styles.iconDiv6}></div>
                <div className={styles.numberDiv}>
                  <p className={styles.first}>
                    0 <span className={styles.second}>6</span>
                  </p>
                </div>
              </div>
              <div className={styles.bodyContainer}>
                <div className={styles.body}>
                  <p className={styles.title}>Email Marketing</p>
                  <p className={styles.about}>
                    We understand the importance of mobile presence in today's
                    digital landscape. We offer comprehensive mobile development
                    services to help you reach and engage your audience on the
                    go. Here are some of our mobile development offerings.
                  </p>
                </div>
                <div className={styles.detail}>
                  <span>Detail</span>
                  <div className={styles.arrowIconDiv}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.mobileArrowDiv}>
        <div
          onClick={() => slide(-310)}
          className={`${styles.leftArrow} ${styles.arrowIcon}`}
        ></div>
        <div
          onClick={() => slide(+310)}
          className={`${styles.rightArrow} ${styles.arrowIcon}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default Index;
