import React from "react";
import styles from './WhyCloud.module.scss'

const MobileCard = (props) => {
  return (
    <div className={styles.mobileCard}>
      <img src={props?.image} className={styles.cardIcon} alt="" />
      <p className={styles.cardTitle}>{props?.title}</p>
      <p className={styles.cardPara}>{props?.info}</p>
    </div>
  );
};

export default MobileCard;
