import React from "react";
import styles from './WhyCloud.module.scss'
import Slider from "../../assets/cloudncloud/slider.png";

const SingleCard = (props) => {
  return (
    <div className={styles.singleCard}>
      <div className={styles.leftOuter}>
        <img src={Slider} className={styles.leftSlider} alt="" />
      </div>
      <div className={styles.rightOuter}>
        <img src={Slider} className={styles.rightSlider} alt="" />
      </div>

      <div className={styles.cardContent}>
        <div className={styles.innerCard}>
          <img src={props?.image} className={styles.cardIcon} alt="" />
          <p className={styles.cardTitle}>{props?.title}</p>
          <p className={styles.cardPara}>
           {props?.info}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
