import React, { useState } from "react";
import styles from "./Footer.module.scss";
import { FOOTER_ICONS } from "../../utils/constants";
import { MobileFooter } from "../../utils/constants";
import Arrow from "../../assets/footer/downArrow.png";

const Index = () => {
  const [data, setData] = useState({
    address: false,
    contact: false,
    mail: false,
    links: false,
    services: false,
  });

  const handleEvent = (name) => {
    setData({ ...data, [name]: !data[name] });
  };
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.title}>
          <img src={FOOTER_ICONS.CLOUD} alt="logo" />
          <span>Cloudncloudtech.com</span>
        </div>
        <div className={styles.inner}>
          <div className={styles.socialDiv}>
            <div className={styles.innerBlock}>
              <div className={styles.addresstitle}>
                <img src={FOOTER_ICONS.LOCATION} alt="logo" />
                <span>Address</span>
              </div>
              <p>
                Corporate Office : 1265, Sector 8-C, Chandigarh, India, Pincode:
                160009
              </p>
            </div>

            <div className={styles.innerBlock}>
              <div className={styles.title}>
                <img src={FOOTER_ICONS.CALL} alt="Contact Logo" />
                <span>Contact</span>
              </div>
              <p>+91-9897381555</p>
              <p>+91-9897381555</p>
            </div>

            <div className={styles.innerBlock}>
              <div className={styles.title}>
                <img src={FOOTER_ICONS.EMAIL} alt="Email logo" />
                <span>Mail Us</span>
              </div>
              <p>Abc123@gmail.com</p>
            </div>

            <div className={styles.innerBlock}>
              <p>Follow us on</p>
              <div className={styles.iconsDiv}>
                <img src={FOOTER_ICONS.LINKEDLN} alt="Likedln" />
                <img src={FOOTER_ICONS.FACEBOOK} alt="Facebook" />
                <img src={FOOTER_ICONS.TWITTER} alt="Twitter" />
                <img src={FOOTER_ICONS.INSTAGRAM} alt="Instagram" />
              </div>
            </div>
          </div>

          <div className={styles.linksDiv}>
            <div>
              <p className={styles.linkTitle}>Quick Links</p>
              <div className={styles.linkList}>
                <p>Home</p>
                <p>About</p>
                <p>Services</p>
                <p>Technology</p>
                <p>Why we</p>
              </div>
            </div>
            <div>
              <p className={styles.linkTitle}>Services</p>
              <div className={styles.linkList}>
                <p>Website Development</p>
                <p>Mobile Development</p>
                <p>Digital Marketing</p>
                <p>Content Writing</p>
                <p>Reputation Management</p>
                <p>Email Marketing</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mobileFooter}>
        {MobileFooter.map((item) => {
          return (
            <>
              <div className={styles.singleDetail}>
                <div className={styles.left}>
                  <img src={item.logo} alt="" />
                  <p>{item.title}</p>
                </div>
                <img
                  className={
                    data?.[item?.name] ? `${styles.rotate}` : `${styles.right}`
                  }
                  onClick={() => handleEvent(item?.name)}
                  src={Arrow}
                  alt=""
                />
              </div>
              {data?.[item?.name] && (
                <div className={styles.detail}>
                  {item?.detail.map((elem) => (
                    <p>{elem}</p>
                  ))}
                </div>
              )}
            </>
          );
        })}

        <p className={styles.followUs}>Follow us on</p>
        <div className={styles.mobileIconsDiv}>
          <img src={FOOTER_ICONS.LINKEDLN} alt="Likedln" />
          <img src={FOOTER_ICONS.FACEBOOK} alt="Facebook" />
          <img src={FOOTER_ICONS.TWITTER} alt="Twitter" />
          <img src={FOOTER_ICONS.INSTAGRAM} alt="Instagram" />
        </div>
      </div>
    </>
  );
};

export default Index;
