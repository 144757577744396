import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import styles from "./Carousel.module.scss";
import Arrow from "../../assets/carousel/whiteArrow.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import ProgressButton from "./ProgressButton";

const CarouselData = [
  { id: 1, styles: styles.carousel1 },
  { id: 2, styles: styles.carousel2 },
  { id: 3, styles: styles.carousel3 },
];
const MobileCarouselData = [
  { id: 1, styles: styles.mobileCarousel1 },
  { id: 2, styles: styles.mobileCarousel2 },
  { id: 3, styles: styles.mobileCarousel3 },
];

const Index = () => {
  return (
    <>
      <div className={styles.carouselDiv}>
        <Carousel
          interval={null}
          // activeIndex={page}
          controls={true}
          indicators={true}
          // pause={true}
        >
          {CarouselData.map((elem) => {
            return (
              <Carousel.Item key={elem?.id}>
                <div className={`${styles.carousel} ${elem?.styles}`}>
                  <div className={styles.btn}>
                    <p>Get Quote</p>
                    <img
                      src={Arrow}
                      className={styles.arrowIcon}
                      alt="arrowIcon"
                    />
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <div className={styles.MobileCarouselDiv}>
        <Carousel
          interval={null}
          // activeIndex={page}
          controls={true}
          indicators={true}
          // pause={true}
        >
          {MobileCarouselData.map((elem) => {
            return (
              <Carousel.Item key={elem?.id}>
                <div className={`${styles.carousel} ${elem?.styles}`}>
                  {/* <div className={styles.btn}>
                  <p>Get Quote</p>
                  <img
                    src={Arrow}
                    className={styles.arrowIcon}
                    alt="arrowIcon"
                  />
                </div> */}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Index;
