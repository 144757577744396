import React from "react";
import styles from "./About.module.scss";
import Exclamation from "../../assets/gifs/exclamation.gif";
import Shadow from "../../assets/F-services/shadow.png";
import { motion } from "framer-motion";
import { AboutCards } from "../../utils/constants";

const Index = () => {
  return (
    <div className={styles.about}>
      <motion.img
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        src={Exclamation}
        className={styles.icon}
        alt="About Icon"
      />
      <div className={styles.inner}>
        <motion.div
          className={styles.textBox}
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <h1 className={styles.title}>About us</h1>
          <p className={styles.para}>
            At Cloudncloudtech.com, we are a leading web development and digital
            marketing company dedicated to providing exceptional services to
            businesses across industries. Our team of talented professionals is
            passionate about helping businesses thrive in the digital landscape.
          </p>
        </motion.div>

        <div className={styles.cardContainer}>
          <motion.div
            className={styles.cardBox}
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {AboutCards.map((item, index) => {
              return (
                <motion.div
                  key={index}
                  className={styles.singleCard}
                  initial={{ y: -15 }}
                  animate={{ y: 0 }}
                  transition={{
                    type: "smooth",
                    repeatType: "mirror",
                    duration: 1,
                    repeat: Infinity,
                  }}
                >
                  <motion.img
                    initial={{ height: 10 }}
                    animate={{ height: 300 }}
                    whileHover={{
                      scale: 1.05,
                      duration: 0.3,
                    }}
                    transition={{
                      type: "smooth",
                      duration: 0.3,
                      repeat: 0,
                      delay: 0,
                    }}
                    className={styles.card}
                    src={item?.cardImage}
                    alt="Mission Card"
                  />
                </motion.div>
              );
            })}
          </motion.div>

          <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className={styles.shadow}
          >
            {AboutCards.map((_, index) => {
              return <img key={index} src={Shadow} alt="shadow" />;
            })}
          </motion.div>
        </div>

        <div className={styles.mobilecardContainer}>
          <motion.div
            className={styles.cardBox}
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {AboutCards.map((item, index) => {
              return (
                <div className={styles.mobileSingleCover} key={index}>
                  <motion.div
                    className={styles.singleCard}
                    initial={{ y: -15 }}
                    animate={{ y: 0 }}
                    transition={{
                      type: "smooth",
                      repeatType: "mirror",
                      duration: 1,
                      repeat: Infinity,
                    }}
                  >
                    <motion.img
                      initial={{ height: 10 }}
                      animate={{ height: 400 }}
                      whileHover={{
                        scale: 1.05,
                        duration: 0.3,
                      }}
                      transition={{
                        type: "smooth",
                        duration: 0.3,
                        repeat: 0,
                        delay: 0,
                      }}
                      className={styles.card}
                      src={item?.cardImage}
                      alt="Mission Card"
                    />
                  </motion.div>
                  <img className={styles.mobileShadow} key={index} src={Shadow} alt="shadow" />
                </div>
              );
            })}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Index;
