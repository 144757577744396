import React from "react";
import styles from "./ContactForm.module.scss";
import ContactGif from "../../assets/gifs/contact.gif";
import { CONTACT_ICONS } from "../../utils/constants";

const Index = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("form Submit");
  };

  return (
    <div className={styles.contactForm}>
      <div className={styles.text}>
        <img src={ContactGif} className={styles.contactGif} alt="Contact Gif" />
        <div className={styles.heading}>
          <h2>How Can We</h2>
          <h2>Help You</h2>
        </div>
        <div className={styles.para}>
          <p>Let’s start the conversation</p>
          <p>
            We value your interest and look forward to connecting with you.
            Don't hesitate to reach out, and we'll be happy to assist you with
            any inquiries or requests you may have.
          </p>
        </div>
      </div>
      <div className={styles.formDiv}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputDiv}>
            <img
              src={CONTACT_ICONS.NAME}
              className={styles.contactIcon}
              alt="Name Icon"
            />
            <input className={styles.input} type="text" placeholder="Name" />
          </div>
          <div className={styles.inputDiv}>
            <img
              src={CONTACT_ICONS.EMAIL}
              className={styles.contactIcon}
              alt="Email Icon"
            />
            <input className={styles.input} type="text" placeholder="Email" />
          </div>
          <div className={styles.inputDiv}>
            <img
              src={CONTACT_ICONS.MOBILE}
              className={styles.contactIcon}
              alt="Mobile Icon"
            />
            <input
              className={styles.input}
              type="text"
              placeholder="Mobile no."
            />
          </div>
          <div className={styles.inputDiv}>
            <div className={styles.countryDiv}>
              <img
                src={CONTACT_ICONS.COUNTRY}
                className={styles.w_20}
                alt="Country Icon"
              />
              <input
                className={styles.input}
                type="text"
                placeholder="Country"
              />
            </div>
            <img
              src={CONTACT_ICONS.DROPDOWN}
              className={styles.contactIcon}
              alt="Country Icon"
            />
          </div>
          <div className={`${styles.inputDiv} ${styles.messageDiv}`}>
            <img
              src={CONTACT_ICONS.MESSAGE}
              className={styles.w_20}
              alt="Message Icon"
            />
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              rows="3"
              cols="55"
              type="text"
              placeholder="Message"
            />
          </div>
          <button className={styles.submitBtn} type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Index;
