import React, { Fragment } from "react";
import styles from "./Home.module.scss";
import Navbar from "../../components/Navbar";
import Carasouel from "../../components/Carousel";
import About from "../../components/About";
import FeatureServices from "../../components/FeatureServices";
import Technology from "../../components/Technology";
import WhyCloud from "../../components/WhyCloud";
import ContactForm from "../../components/ContactForm";
import Footer from "../../components/Footer";


const index = () => {
  return (
    <div className={styles.home}>
      <Navbar />
      <Carasouel/>
      <About/>
      <FeatureServices/>
      <Technology/>
      <WhyCloud/>
      <ContactForm/>
      <Footer/>
    </div>
  );
};

export default index;
