import NameIcon from "../assets/contactIcons/name.png";
import EmailIcon from "../assets/contactIcons/email.png";
import CountryIcon from "../assets/contactIcons/country.png";
import MessageIcon from "../assets/contactIcons/messge.png";
import MobileIcon from "../assets/contactIcons/mobile.png";
import Dropdown from "../assets/contactIcons/dropdown.png";

import Cloud from "../assets/footer/cloud.png";
import Location from "../assets/footer/location.png";
import Call from "../assets/footer/Call.png";
import Email from "../assets/footer/Email.png";
import Linkedln from "../assets/footer/linkedln.png";
import Facebook from "../assets/footer/facebook.png";
import Twitter from "../assets/footer/twitter.png";
import Instagram from "../assets/footer/instagram.png";
import Links from "../assets/footer/links.png";
import Services from "../assets/footer/services.png";

import Mission from "../assets/aboutCards/missions.png";
import Vision from "../assets/aboutCards/vision.png";
import Values from "../assets/aboutCards/values.png";

export const CONTACT_ICONS = {
  NAME: NameIcon,
  EMAIL: EmailIcon,
  COUNTRY: CountryIcon,
  MESSAGE: MessageIcon,
  MOBILE: MobileIcon,
  DROPDOWN: Dropdown,
};

export const FOOTER_ICONS = {
  CLOUD: Cloud,
  LOCATION: Location,
  CALL: Call,
  EMAIL: Email,
  LINKEDLN: Linkedln,
  FACEBOOK: Facebook,
  TWITTER: Twitter,
  INSTAGRAM: Instagram,
};

export const AboutCards = [
  { cardImage: Mission },
  { cardImage: Vision },
  { cardImage: Values },
];

export const MobileFooter = [
  {
    title: "Address",
    name:"address",
    logo: Location,
    detail: [
      "Corporate Office : 1265, Sector 8-C, Chandigarh, India, Pincode: 160009",
    ],
  },
  {
    title: "Contact",
    name:'contact',
    logo: Call,
    detail: ["+91-9897381555", "+91-9897381555"],
  },
  {
    title: "Mail Us",
    name:'mail',
    logo: Email,
    detail: ["Abc123@gmail.com", "Abc123@gmail.com"],
  },
  {
    title: "Quick Links",
    name:"links",
    logo: Links,
    detail: ["Home", "About Us", "Services", "Technology", "Why we"],
  },
  {
    title: "Services",
    name:'services',
    logo: Services,
    detail: [
      "Website Development",
      "Mobile Development",
      "Digital Marketing",
      "Content Writing",
      "Reputation Management",
      "Email Marketing",
    ],
  },
];
