import React from "react";
import styles from "./WhyCloud.module.scss";
import QuestionGif from "../../assets/gifs/question.gif";
import Marquee from "react-fast-marquee";
import SingleCard from "./SingleCard";
import { CloudnCloudInfo } from "./../../utils/cloudncloudCards";
import MobileCard from "./MobileCard";

const index = () => {
  return (
    <div className={styles.main}>
      <div className={styles.whyCloud}>
        <div className={styles.text}>
          <img
            src={QuestionGif}
            className={styles.questionIcon}
            alt="Question mark"
          />
          <h2>Why</h2>
          <h2>cloudncloud</h2>
        </div>
        <div className={styles.para}>
          <p>Why Choose Us</p>
          <p>
            There are several reasons why you should choose CloudnCloudtech.com
            for your web development and digital marketing needs.{" "}
          </p>
        </div>
      </div>

      <div className={styles.sliderDiv}>
        <Marquee
          className={styles.marque}
          gradient={false}
          speed={80}
          pauseOnHover={true}
          pauseOnClick={true}
          delay={0}
          play={true}
          direction="left"
        >
          {CloudnCloudInfo.map((elem) => (
            <SingleCard key={elem?.id} {...elem} />
          ))}
        </Marquee>
      </div>

      <div className={styles.mobileCardsContainer}>
        {CloudnCloudInfo.map((elem) => (
          <MobileCard key={elem.id} {...elem} />
        ))}
      </div>
    </div>
  );
};

export default index;
