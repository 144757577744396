import React from "react";
import styles from "./Technology.module.scss";
import SmallCircle from "../../assets/technologies/smallcircle.png";
import BigCircle from '../../assets/technologies/bigcircle.png'
import Web from '../../assets/gifs/webnet.gif'
import BlackLine from '../../assets/technologies/blackLine.png'
import mobileLine from '../../assets/technologies/mobileLine.png'

const index = () => {
  return (
    <div className={styles.technology}>
      <div className={styles.text}>
        <img src={Web} className={styles.web} alt="" />
        <h2 className={styles.firstText}>Tools &</h2>
        <h2 className={styles.secondText}>Technologies</h2>
        <div className={styles.para}>
          <p>Technologies we are working on</p>
          <p>
            we leverage a range of tools and technologies to deliver exceptional
            results in web development and digital marketing. Here are some of
            the key tools and technologies we utilize:
          </p>
        </div>
      </div>
      <div className={styles.image}>
        <img src={BlackLine} className={styles.topLine} alt="" />
        <img src={mobileLine} className={styles.mobiletopLine} alt="" />
        <div className={styles.gifContainer}>
          <div className={styles.outerouter}>
            <div className={styles.smallOuter}></div>
            <img src={SmallCircle} className={styles.smallCircle} alt="" />
          </div>

          <div className={styles.outerouter}>
            <div className={styles.bigOuter}></div>
            <img src={BigCircle} className={styles.bigCircle} alt="" />
          </div>

        </div>
        <img src={BlackLine} className={styles.bottomLine} alt="" />
        <img src={mobileLine} className={styles.mobilebottomLine} alt="" />
      </div>
    </div>
  );
};

export default index;
