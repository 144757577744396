import Bulb from "../assets/cloudncloud/bulb.png";
import Electronics from "../assets/cloudncloud/Electronics.png";
import TestPassed from '../assets/cloudncloud/TestPassed.png';
import BrainStorm from '../assets/cloudncloud/Brainstorm.png';
import Client from '../assets/cloudncloud/Client.png';
import Comprehensive from '../assets/cloudncloud/comprehensive.png'

export const CloudnCloudInfo = [
  {
    id: 1,
    image: Bulb,
    title: "Customized Solutions",
    info: `We believe in delivering tailor-made solutions that align with your specific goals and objectives. We take the time to understand your business, target audience, and industry landscape to create customized strategies that drive results.`,
  },
  {
    id: 2,
    image: Electronics,
    title: "Cutting-Edge Technology",
    info: `We stay up to date with the latest trends, tools, and technologies in web development and digital marketing. By leveraging cutting-edge technology and best practices, we ensure that our solutions are modern, efficient, and effective in delivering the desired outcomes.`,
  },
  {
    id: 3,
    image: TestPassed,
    title: "Results-Driven Approach",
    info: `Our primary goal is to help your business succeed. We adopt a results-driven approach, constantly monitoring, analyzing, and optimizing our strategies to maximize your return on investment. We set clear objectives, track key performance indicators, and provide transparent reporting to keep you
    `,
  },
  {
    id: 4,
    image: Client,
    title: "Client Collaboration",
    info: `We believe in building strong partnerships with our clients based on collaboration and communication. We involve you throughout the process, seeking your input, feedback, and ideas to ensure that the final deliverables meet and exceed your expectations.`
  },
  {
    id: 5,
    image: Comprehensive,
    title: "Comprehensive Services",
    info: `From web development to digital marketing, we offer a comprehensive suite of services that cover all aspects of establishing and growing your online presence. Whether you need a stunning website, increased online visibility, or engaging digital campaigns.`,

  },
  {
    id: 6,
    image: BrainStorm,
    title: "Expertise and Experience",
    info: `Our team consists of highly skilled professionals with extensive experience in web development and digital marketing. We have successfully completed numerous projects across various industries, enabling us to understand the unique challenges and requirements of different businesses.`,
  },
  
];
