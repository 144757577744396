import React from 'react'
import styles from './Navbar.module.scss'
import Phone from '../../assets/phone.png'
import Logo from '../../assets/logo.png'
import Hamburger from '../../assets/hamburger.png'


const index = () => {
  return (
    <div className={styles.navbar}>
      <img className={styles.hamburger} src={Hamburger} alt="logo"  />
      <img className={styles.logo} src={Logo} alt="logo"  />
      <ul className={styles.links} >
        <li className={styles.link} >About us</li>
        <li className={styles.link} >Services</li>
        <li className={styles.link} >Carrers</li>
        <li className={styles.link} >Contact</li>
      </ul>
      <div className={styles.contact}>
        <img className={styles.phonelogo} src={Phone} alt="icon" />
        <span className={styles.phoneText}>+91- 9867453423</span>
      </div>
    </div>
  )
}

export default index
